import React from 'react';
import { navigate } from 'gatsby';

class NotFoundPage extends React.Component {
  componentDidMount() {
    // if (typeof window !== "undefined") window.location = '/blog/';
    navigate('/blog/');
  }

  render() {
    return null;
  }
}

export default NotFoundPage;
